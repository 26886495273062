import {shippingDetails} from '@delorand/db/schema/customer';
import {checkouts, orders} from '@delorand/db/schema/order';
import {createInsertSchema} from 'drizzle-zod';
import {z, ZodTypeAny} from 'zod';

export type SToType<T extends ZodTypeAny> = z.infer<T>;

export const statusEnum = z.enum(['ACTIVE', 'DISABLED']);

export const rawFileS = z.object({
  serverUrl: z.string().nullish(),
  file: z.any().nullish(),
  previewUrl: z.string().nullish(),
});

const fileSchema = rawFileS.refine(
  schema => (schema.file || schema.serverUrl ? true : false),
  {
    message: 'Select an image',
  }
);

export const shippingDetailsS = createInsertSchema(shippingDetails).omit({
  default: true,
  customerId: true,
});

export type ShippingDetailsS = z.infer<typeof shippingDetailsS>;

export const scsS = z.array(
  z.object({
    storeId: z.number(),
    cid: z.number(),
  })
);

export const checkoutItemS = z.object({
  variantId: z.number(),
  quantity: z.number(),
});

export const checkoutS = createInsertSchema(checkouts).extend({
  items: z.array(checkoutItemS),
  storesCarriers: scsS,
  // shippingDetails: shippingDetailsS,
});

export const placeOrderS = z.object({
  checkoutId: z.number(),
  items: z.array(
    z.object({
      variantId: z.number(),
      quantity: z.number(),
    })
  ),
  deliveryDetails: shippingDetailsS,
  storesCarriers: scsS,
  couponIds: z.array(z.number()),
});

export type PlaceOrderS = z.infer<typeof placeOrderS>;

export const userS = z.object({
  firstName: z.string().min(1, 'Invalid').max(50, 'Name too long'),
  lastName: z.string().max(100).nullable(),
  phoneNumber: z.string().nullable(),
  email: z.string().email({message: 'email required'}),
  preferences: z.object({
    newsletters: z.boolean(),
  }),
  shippingDetails: shippingDetailsS.array(),
  userName: z
    .string()
    .min(4, 'Must be greater than 4')
    .max(20, 'Username too long'),
});

export const userFormS = userS.extend({image: rawFileS}).omit({
  preferences: true,
  shippingDetails: true,
});

export type UserFormS = z.infer<typeof userFormS>;
export type UserS = z.infer<typeof userS>;

export const loginS = z.object({
  email: z.string().email().min(1, 'required').max(100, 'invalid'),
  password: z.string().min(6, 'enter a valid password').max(200, 'invalid'),
});

export type LoginS = z.infer<typeof loginS>;

export const signupS = loginS
  .extend({
    confirmPassword: z.string().min(6, 'required').max(200, 'invalid'),
  })
  .superRefine(({password}, ctx) => {
    const containsUppercase = (ch: string) => /[A-Z]/.test(ch);
    const containsLowercase = (ch: string) => /[a-z]/.test(ch);
    const containsSpecialChar = (ch: string) =>
      /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/.test(ch);
    let countOfUpperCase = 0,
      countOfLowerCase = 0,
      countOfNumbers = 0,
      countOfSpecialChar = 0;
    for (let i = 0; i < password.length; i++) {
      let ch = password.charAt(i);
      if (!isNaN(+ch)) countOfNumbers++;
      else if (containsUppercase(ch)) countOfUpperCase++;
      else if (containsLowercase(ch)) countOfLowerCase++;
      else if (containsSpecialChar(ch)) countOfSpecialChar++;
    }
    if (
      countOfLowerCase < 1 ||
      countOfUpperCase < 1 ||
      countOfSpecialChar < 1 ||
      countOfNumbers < 1
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Weak Password',
        path: ['password'],
      });
    }
  })
  .refine(({password, confirmPassword}) => password === confirmPassword, {
    message: "passwords don't match",
    path: ['confirmPassword'],
  });
export type SignupS = z.infer<typeof signupS>;

export const itemsSortS = z
  .enum(['highest-price', 'lowest-price', 'orders', 'best-match'])
  .nullish()
  .optional();

export type ItemsSort = z.infer<typeof itemsSortS>;

export const verifyS = z.object({
  reference: z.string(),
});

export type VerifyS = z.infer<typeof verifyS>;

export const cartModS = z.object({
  add: z
    .object({
      items: z.array(
        z.object({
          quantity: z.number().optional(),
          variantId: z.number(),
        })
      ),
      storesCarriers: z.array(
        z.object({
          storeId: z.number(),
          cid: z.number(),
        })
      ),
    })
    .optional(),
  remove: z
    .object({
      type: z.enum(['ONE', 'MANY', 'ALL']),
      variantIds: z.array(z.number()),
    })
    .optional(),
  update: z
    .object({
      variantId: z.number(),
      type: z.enum(['INCR', 'DECR']),
      quantity: z.number().optional(),
    })
    .optional(),
});

export type CartModS = z.infer<typeof cartModS>;

export const categoryS = z.object({
  name: z.string().min(1, 'required').max(200, 'invalid'),
  slug: z.string().min(1, 'required').max(200, 'invalid').toLowerCase(),
  level: z.number().min(1, 'required').max(200, 'invalid'),
  minPrice: z.number(),
  maxPrice: z.number(),
  commission: z.number().nullish(),
  disabled: z.boolean().optional(),
});
export type CategoryS = z.infer<typeof categoryS>;
export const emailS = z.string().email('Enter a valid email');

export const brandS = z.object({
  name: z.string().toLowerCase(),
});
export type BrandS = z.infer<typeof brandS>;

export const changePasswordS = z.object({
  old: z.string().min(6),
  new: z.string().min(6),
  confirm: z.string().min(6),
});
export type ChangePasswordS = z.infer<typeof changePasswordS>;

export const groupCartS = z.object({
  name: z.string().min(1, 'Enter a valid name'),
  items: z.array(z.string()),
  members: z.array(z.string()).min(1, 'you must add at least one friend'),
});

export type GroupCartS = z.infer<typeof groupCartS>;

export const addToGroupCartS = z.object({
  groupCartId: z.string(),
  addedById: z.string(),
  item: z.object({
    id: z.string(),
    quantity: z.number(),
  }),
});

export type AddToGroupCartS = z.infer<typeof addToGroupCartS>;

export const searchCollectS = z.object({
  hint: z.string(),
  clickedProductId: z.number().optional(),
});
export type SearchCollectS = z.infer<typeof searchCollectS>;

export const updateShipmentS = z.object({
  carrierUsedId: z.number().min(1, 'Enter Carrier Used'),
  trackingCode: z.string().optional(),
  ETA: z.object({
    from: z.date(),
    to: z.date(),
  }),
  cost: z.number().optional(),
  comments: z.string().optional(),
});

export type UpdateShipmentS = z.infer<typeof updateShipmentS>;

export const notificationTypeS = z.enum([
  'order',
  'support',
  'complaint',
  'all',
]);
export const notificationStatus = z.enum(['sealed', 'opened']);

export const storeMessageS = z.object({
  type: z.enum(['TEXT', 'IMAGE', 'VIDEO', 'COUPON']),
  messageText: z.string(),
  mediaURL: z.string().optional(),
  couponId: z.number().optional(),
});
export type StoreMessageS = z.infer<typeof storeMessageS>;

export const promotionS = z.object({
  type: z.enum(['IMAGE', 'PRODUCTS', 'STORE']),
  image: rawFileS.optional(),
  productIds: z.array(z.number()).optional(),
  links: z
    .array(
      z.object({
        id: z.string(),
        url: z.string(),
      })
    )
    .optional(),
  priority: z.number().default(1),
  tags: z.array(z.string()).optional(),
  title: z.string().optional(),
  countDownTo: z.date().or(z.string()).optional(),
});

export type PromotionS = z.infer<typeof promotionS>;

export const productPromotionS = promotionS
  .pick({
    priority: true,
    productIds: true,
  })
  .extend({
    title: z.string(),
    expiresIn: z.number().optional(),
  });
export type ProductPromotionS = z.infer<typeof productPromotionS>;

export const imagePromotionS = z.object({
  image: fileSchema,
  productId: z.string().optional(),
  link: z.string().optional(),
  priority: z.number().default(1),
});
export type ImagePromotionS = z.infer<typeof imagePromotionS>;

export const contactS = z.object({
  name: z.string().optional(),
  email: z.string().email().min(1, 'Required'),
  subject: z.string().min(1, 'Required'),
  message: z.string().min(1, 'Required'),
});

export type ContactS = z.infer<typeof contactS>;

export const penaltyS = z.object({
  storeId: z.string().min(1, 'Required'),
  orderId: z.string().optional(),
  productId: z.string().min(1, 'Required'),
  violationType: z.enum(['']),
  action: z.enum(['']),
  violatedAt: z.date(),
  description: z.string(),
  value: z.number().default(0),
  comments: z.string(),
});

export type PenaltyS = z.infer<typeof penaltyS>;

export const orderUpdateS = createInsertSchema(orders);

export * from './product';
export * from './vendor';
