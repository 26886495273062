import {stores, vendors} from '@delorand/db/schema/store';
import {createInsertSchema} from 'drizzle-zod';
import {z} from 'zod';

const fileS = z.object({
  serverUrl: z.string().nullish(),
  file: z.custom<Blob>().nullish(),
  previewUrl: z.string().nullish(),
});

const requiredfileS = fileS.refine(
  schema => (schema.file || schema.serverUrl ? true : false),
  {
    message: 'Select an image',
  }
);

export const locationS = z.object({
  city: z.string(),
  state: z.string(),
  address: z.string(),
});

export const vendorLoginS = z.object({
  accessId: z.string(),
  key: z.string().length(12, 'invalid'),
});

export type VendorLoginS = z.infer<typeof vendorLoginS>;

export const vendorS = createInsertSchema(vendors, {
  location: locationS,
  firstName: z.string().min(1, 'Required'),
  lastName: z.string().min(1, 'Required'),
  phoneNumber: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required'),
});

export type VendorS = z.infer<typeof vendorS>;
export const vendorFormS = vendorS
  .omit({
    image: true,
    role: true,
    status: true,
  })
  .extend({image: fileS.optional()});
export type VendorFormS = z.infer<typeof vendorFormS>;

export const storeS = createInsertSchema(stores, {
  location: locationS,
  bankAccount: z.object({
    bank: z.string(),
    accountNumber: z.string(),
    name: z.string(),
  }),
  storeCode: z.string().optional(),
})
  .omit({
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    carrierIds: z.array(z.number()).default([]),
  });

// export type StoreS = z.infer<typeof storeS>;

export const storeFormS = storeS
  .omit({
    status: true,
    image: true,
  })
  .extend({
    image: requiredfileS,
  });

export type StoreFormS = z.infer<typeof storeFormS>;

export const productSortEnum = z.enum([
  'title-asc',
  'title-desc',
  'price-asc',
  'price-desc',
  'stock-asc',
  'stock-desc',
  'sold-asc',
  'sold-desc',
  'search',
]);
